import { decodeJwtPayload } from '@nebular/auth';
import { NbMenuItem } from '@nebular/theme';
let user;
const check_user = () => {
    if (localStorage.getItem('auth_app_token')) {
        const decoded_user = JSON.parse(localStorage.getItem('auth_app_token'));
        user = decodeJwtPayload(decoded_user?.value);
        console.log("user",user)
    }
};
check_user();
export const MENU_ITEMS: NbMenuItem[] = [
    {
        title: 'لوحة التحكم',
        icon: 'grid-outline',
        link: '/pages/dashboard',
        home: true,
    },
    {
        title: 'النشاط التجاري',
        icon: 'home',
        children: [
            {
                title: 'الأنشطة التجارية',
                icon: 'trending-up-outline',
                link: '/pages/business/business-index',
                data: { role: 'Owner' || 'Admin' },
            },
            {
                title: 'الفروع',
                icon: 'trending-up-outline',
                link: '/pages/branches/branches-index',
                data: { permissions: 'support_additionsing' },
            },
            {
                title: 'تغطية الفروع',
                icon: 'trending-up-outline',
                link: '/pages/branches/branches-coverage',
                data: { role: 'Owner' },
            },
            {
                title: 'أضف فرع ',
                link: '/pages/admin/create_commercial',
                hidden: user?.role !== 'Admin',
            },
        ],
    },
    {
        title: 'المنتجات ',
        icon: 'pricetags-outline',
        children: [
            {
                title: 'المنتجات ',
                icon: 'map-outline',
                // link: '/pages/settings/catalog/products/products-index',
                link: '/pages/products/products-index',
            },
            {
                title: 'إضافة منتجات ',
                icon: 'map-outline',
                // link: '/pages/settings/catalog/products/products-index',
                link: '/pages/products/create-product',
                data: { role: 'Owner', commercialTypeId: "1" },
            },
            {
                title: 'اضافه منتجات الماركت',
                icon: 'map-outline',
                link: '/pages/settings/catalog/catalog-index',
                data: { role: 'Owner', commercialTypeId: "2" },
            },
        ],
    },
    {
        title: 'الاعدادات ',
        icon: 'settings-2-outline',
        children: [
            {
                title: 'المستخدمين ',
                icon: 'person-outline',
                link: '/pages/settings/users/users-index',
                data: { role: 'Owner' },
            },
            {
                title: ' وسائل الدفع ',
                icon: 'credit-card-outline',
                link: '/pages/settings/payment-methods',
            },
            {
                title: 'الوظائف ',
                icon: 'person-done-outline',
                link: '/pages/settings/permissions/permissions-index',
            },

    
        ],
    },

    {
        title: 'الطلبات',
        icon: 'shopping-cart-outline',
        children: [
            {
                title: 'الطلبات الحاليه',
                icon: 'cloud-download-outline',
                link: '/pages/accounts/orders/income-orders',
            },
            {
                title: ' الطلبات السابقه',
                icon: 'shopping-cart-outline',
                link: '/pages/accounts/orders/orders-index',
            },
        ],
    },
];
